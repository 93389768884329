import { graphql } from 'gatsby'
import * as React from 'react'

import CompanySurveyPage from '../components/pages/CompanySurveyPage'
import DefaultLayout from '../layouts'

const CompanySurvey = () => (
  <DefaultLayout
    language="en"
    title="The State of Freelancing in Israel 2020 - Clients"
    description="Freelancers and industry leaders give their thoughts on the current state of freelancing in Israel"
  >
    <CompanySurveyPage />
  </DefaultLayout>
)

export default CompanySurvey

export const pageQuery = graphql`
  query CompanySurveyQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
